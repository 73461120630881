.mobile-coverage {
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 24px;
  background: #3B3B40;
  border-radius: 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: center; /* added */
  margin: 12px 0px;
  font-family: 'Space Grotesk';
}

.mobile-coverage-text {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;


    font-family: 'Space Grotesk';
    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.mobile-coverage-text-description {
/* Description */

/* New ID/description */

font-family: 'HK Grotesk';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 130%;
/* or 19px */

display: flex;
align-items: center;
text-align: center;

/* UI Colours/Gray 4 */

color: #AEAEB7;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.mobile-coverage-text-title {
/* Title */



/* New ID/h3 medium (Space) */

font-family: 'Space Grotesk';
font-style: normal;
font-weight: 500;
font-size: 21px;
line-height: 170%;
/* or 27px */

display: flex;
align-items: center;
text-align: center;

/* UI Colours/Gray 5 */

color: #E1E1E7;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.mobile-coverage-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    width: 48px;
    height: 48px;

    /* UI Colours/Gray 3 Hover */

    background: #727275;
    border-radius: 10.5px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0
}

.mobile-coverage-image-container {
    flex: none;
    order: 0;
    flex-grow: 1;
}