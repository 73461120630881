.DEFA-App {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 12px 64px;
    gap: 24px;
    height: 100vh;
    /* UI Colours/Gray 1 */

    background: #2A2A30;
}

.defa-body {
    background: #2A2A30;
    height: 100vh;
    }
    
    .container {
    max-width: 768px; /* Set the maximum width for large screens */
    margin: 0 auto; /* Center the container horizontally */
    padding: 0 20px; /* Optional padding */
    }
    
    .defa-elements {
    width: 75%;
    }
    
    @media (max-width: 768px) {
    .container {
    max-width: 100%; /* Allow full width on smaller screens */
    padding: 0 10px; /* Adjust padding as needed */
    }
    .defa-elements {
    width: 100%;
    }
    }