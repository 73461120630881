.defa-charger {
    /* Auto layout */

display: flex;
flex-direction: column;
align-items: flex-start;
padding: 12px 24px;
margin: 12px 0px;

/* UI Colours/Gray 2 Darker */

background: #3B3B40;
border-radius: 12px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}

.defa-charger-element {
    display: flex;
    flex-direction:row;
    align-items: center;
    padding: 0px;
    gap:16px;
}

.defa-charger-image {
    flex: none;
    width: 45px;
    height: 56px;
}

.defa-charger-text {
    flex: none;
}

.defa-charger-text-header {
    font-family: "HK Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #ffffff;
    display: flex;
    align-items: flex-end;
}

.defa-charger-text-description {
    font-family: "HK Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: #AEAEB7;
    display: flex;
    align-items: flex-end;
}