
.buttonContainer {
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 12px 0px;

/* UI Colours/Gray 2 */

background: #4C4C51;
border-radius: 20px;

/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;
}

.buttonChild {

/* New ID/h4 */

font-family: 'HK Grotesk';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 130%;
/* or 23px */

text-align: center;

/* UI Colours/White */

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.buttonContainer:hover {
    cursor: pointer;
}