@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk'), url(../fonts/SpaceGrotesk/SpaceGrotesk-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk'), url(../fonts/SpaceGrotesk/SpaceGrotesk-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk'), url(../fonts/SpaceGrotesk/SpaceGrotesk-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk'), url(../fonts/SpaceGrotesk/SpaceGrotesk-SemiBold.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk'), url(../fonts/SpaceGrotesk/SpaceGrotesk-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Light.ttf) format("truetype");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Light.otf) format("opentype");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Light.woff) format("woff");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Light.woff2) format("woff2");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Regular.ttf) format("truetype");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Regular.otf) format("opentype");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Regular.woff) format("woff");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Regular.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Medium.ttf) format("truetype");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Medium.otf) format("opentype");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Medium.woff) format("woff");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Medium.woff2) format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-SemiBold.ttf) format("truetype");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-SemiBold.otf) format("opentype");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-SemiBold.woff) format("woff");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-SemiBold.woff2) format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Bold.ttf) format("truetype");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Bold.otf) format("opentype");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Bold.woff) format("woff");
    src: local('HK Grotesk'), url(../fonts/HKGrotesk/HKGrotesk-Bold.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
}