.text-temp-style {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 170%;
    align-items: center;
    text-align: center;
    color: #E1E1E7;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.text-temp-style:link {
    color: white;
    background-color: transparent;
    text-decoration: none;
 }