.input-container {
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 16px;
padding: 12px 0px;
margin: 12px 0px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
align-self: stretch;
}

input, select, textarea{
    color: #ffffff;
}

.text-container {
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 4px;



/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.header-container {
    display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 8px;

width: 369px;
height: 22px;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}

.header-text {
    /* New ID/h4 */

font-family: 'HK Grotesk';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 130%;
/* identical to box height, or 22px */

display: flex;
align-items: center;

/* UI Colours/White */

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.header-icon {
    flex: none;
    order: 1;
    flex-grow: 0;
    height: 20px;
    width: 20px;
}

.header-help-text {
    font-family: 'HK Grotesk';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 130%;
/* or 19px */

display: flex;
align-items: center;

/* UI Colours/Gray 4 */

color: #AEAEB7;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}

.input-input {
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px 12px;

/* UI Colours/Gray 2 Darker */

background: #3B3B40;
border-radius: 12px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}

