.currency-option {
    font-family: 'HK Grotesk';
    font-style: normal;
    color: #E1E1E7;
    font-size: 20px;
    background: #3B3B40;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    align-items: center;
}

.dropdown-options .dropdown-elem:hover {background-color: #36353b}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-elem {
    color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  border-radius: 20px;
  background-color: #3B3B40;
}
.dropdown-toggle {
width: 335px;
text-align: center;
height: 62px;
border: 2px solid #4C4C51;
left: 0px;

top: 39px;
background: #3B3B40;

border-radius: 20px;
font-family: 'HK Grotesk';

font-style: normal;

font-weight: 400;

font-size: 17px;

line-height: 130%;
cursor: pointer;

/* identical to box height, or 22px */

display: flex;
flex-direction: row;

align-items: center;
text-align: center;
justify-content: center;
letter-spacing: -0.01em;
}

.dropdown-options {
    display: column;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 20px;
    background-color: #3B3B40;
    border: 1px solid #4C4C51;
} 

.dropdown-arrow {
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    justify-content: right;
    align-items: right;
    transform: rotate(45deg);
    transition: transform 0.3s;
    right: 100px;
  }
  
.dropdown-arrow.open {
    transform: rotate(-135deg);
}