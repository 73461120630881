.usernavinfo-text {
    font-family: 'HK Grotesk';
    color: #FFFFFF;
    font-style: normal;
    align-items: center;
    text-align: center;
}

.usernavinfo-text-lower {
    font-weight: 800;
    font-size: 18px;
    line-height: 130%;
}