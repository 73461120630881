.serial-number-container {
    background: #3B3B40;
    border-radius: 12px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 12px 0px;
}


.serial-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 24px;
    background: #3B3B40;
    flex: none;
    order: 1;
    height: 369px;
    align-self: stretch;
    flex-grow: 0;
    text-align: center; /* added */
    position: relative;
    background: url("../../assets/images/background_img.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}

.serial-number-element {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 24px;
    gap: 16px;
    
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    border-radius: 12px;
    background: linear-gradient(180deg, rgba(59, 59, 64, 0) 25.81%, #3B3B40 58.06%);
}

.serial-number-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    width: 48px;
    height: 48px;

    /* UI Colours/Gray 3 Hover */

    background: #727275;
    border-radius: 10.5px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0
}

.serial-number-image-container {
    flex: none;
    order: 0;
    flex-grow: 1;
}

.serial-number-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;


    /* Inside auto layout */
    text-align: left;
    order: 1;
    flex-grow: 1;
}

.serial-number-text-header {

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 130%;
    /* or 27px */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    /* UI Colours/Gray 5 */
    color: #E1E1E7;
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.serial-number-text-description {
    /* Activate internet access immediately on this DEFA Power without any additional setup. */



/* New ID/description */

font-family: 'HK Grotesk';
font-style: normal;
/* or 19px */

display: flex;
align-items: center;

/* UI Colours/Gray 4 */

color: #AEAEB7;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}